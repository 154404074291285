import store from "@/state/store";
// import home from "./routes/general";
import marketing_routes from "./routes/marketing";
// import users_routes from "./routes/users";
// import hr_routes from "./routes/hr";
// import archiving_routes from "./routes/archiving";

// import account from "./routes/account";

// import subscriptions_management from "./routes/pos_manager_platform_routes/subscriptions_management";
// import pos_subscriptions from "./routes/pos_manager_platform_routes/pos_subscriptions";
// import subscription_features from "./routes/pos_manager_platform_routes/subscription_features";
// import pos_hub_subscriptions_management from "./routes/pos_manager_platform_routes/pos_hub_subscriptions_management";
// import pos_hub_subscriptions from "./routes/pos_manager_platform_routes/pos_hub_subscriptions";
// import pos_hub_subscription_features from "./routes/pos_manager_platform_routes/pos_hub_subscription_features";
// import technical_support from "./routes/pos_manager_platform_routes/technical_support";
// import pos_station_profile from "./routes/pos_manager_platform_routes/pos_station_profile/index.js";
// import default_pos_accounting from "./routes/default_pos_accounting";
// import pos_stations from "./routes/pos_manager_platform_routes/pos_stations.js";
// import pos_hubs from "./routes/pos_manager_platform_routes/pos_hubs.js";

export default [
  {
    path: "/login",
    name: "login",

    component: () => import("@/router/views/account/login"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/router/views/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("@/router/views/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/router/views/account/logout.vue"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
  },
  {
    path: "/",
    redirect: "/dashboard/marketing_survey",
  },
  
  {
    path: "/dashboard/home",
    redirect: "/dashboard/marketing_survey",
  },
  
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../components/layouts.vue"),
    children: [
      // ...home,
      // ...subscription_features,
      // ...subscriptions_management,
      // ...pos_subscriptions,
      // ...pos_hub_subscriptions_management,
      // ...pos_hub_subscriptions,
      // ...pos_hub_subscription_features,
      // ...technical_support,
      // ...account,
      // ...hr_routes,
      // ...archiving_routes,
      ...marketing_routes,
      // ...users_routes,
      // ...pos_station_profile,
      // ...default_pos_accounting,
      // ...pos_hubs,
      // ...pos_stations,
      {
        path: "/charts",
        component: () => import("./views/charts/chartist.vue"),
      },
      {
        path: "/charts-apex",
        component: () => import("./views/charts/apex.vue"),
      },
    ],
  },


];
