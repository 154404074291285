import store from "../state/store"
import { jwtDecode } from "jwt-decode";
import { socket } from "../services/socket";
export default {
  install(Vue) {
    var self = Vue.config.globalProperties;
    window.$login = {
      loginUser(email, pass) {
        return new Promise((resolve) => {
          console.log("login clicked");
          self.http.post("users/admin-login", { email, pass }).then((res) => {
            if (res.status) {
              console.log("logged user step 10 :", res);
              socket.connect();
              console.log('step 16 :', pass);
              let user_data = jwtDecode(res.data.token).user_data;
              user_data.pass = self.$e.encrypt(pass);
              resolve(res)
              store.commit("addUserData", user_data);
              window.loginDone = true;
            } else {
              resolve(res)
              console.log("wrong")
            }
          });

        });
      },
      loginMarketing(email, pass) {
        return new Promise((resolve) => {
          console.log("login clicked");
          self.http.post("users/login-marketing-team", { email, pass }).then((res) => {
            if (res.status) {
              console.log("logged user step 10 :", res);
              socket.connect();
              let user_data = jwtDecode(res.data.token).user_data;
              user_data.pass = self.$e.encrypt(pass);
              resolve(res)
              store.commit("addUserData", user_data);
              window.loginDone = true;
            } else {
              resolve(res)
              console.log("wrong")
            }
          });

        });
      },
    };
    self.$login = window.$login;

  },
};
