export default [
  
 


  
  

  
  {
    path: "marketing_districts_management/:id",
    name: "marketing_area_management",
    sensitive:true,
    meta: {
      authRequired: true,
      // can_see: marketing_manager,
      auth_roles: ["admin", "marketing_manager",'marketing_survey_manager_no_edit'],
    },
    component: () => import("../views/Marketing/MarketingAreaPage.vue"),
  },
  {
    path: "marketing_districts_management",
    name: "marketing_districts_management",
    sensitive:true,
    meta: {
      authRequired: true,
      // can_see: marketing_manager,
      auth_roles: ["admin", "marketing_manager",'marketing_survey_manager_no_edit'],
    },
    component: () => import("../views/Marketing/MarketingDistrictsPage.vue"),
  },
  {
    path: "marketing_team_management",
    name: "marketing_team_management",
    meta: {
      authRequired: true,
      // can_see: marketing_manager,
      auth_roles: ["admin", "marketing_manager",'marketing_survey_manager_no_edit'],
    },
    component: () => import("../views/Marketing/MarketingTeamPage.vue"),
  },

  {
    path: "marketing_survey",
    name: "marketing_survey",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager",'marketing_survey_manager_no_edit'],
    },
    component: () => import("../views/Marketing/MarketingSurveyPage.vue"),
  },
  {
    path: "marketing_survey/:id",
    name: "marketing_survey_details",
    sensitive:true,
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager",'marketing_survey_manager_no_edit'],
    },
    component: () =>
        import(
            "../views/Marketing/MarketingSurveyDetails/MarketingSurveyPage.vue"
            ),
  },
 
  {
    path: "marketing_survey/:surveyId/survey-area/:areaId",
    name: "surveyArea",
    component: () =>
      import(
        "../views/Marketing/MarketingSurveyDetails/MarketingSurveyAreasTeamPage.vue"
      ),
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager",'marketing_survey_manager_no_edit'],
    },
  },
];
