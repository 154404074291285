import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import BootstrapVue3 from "bootstrap-vue-3";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from "vue3-scroll-spy";
import jQuery from "jquery";
import VueSocialSharing from 'vue-social-sharing'


import Maska from "maska";
import { i18n } from "./i18n.js";
import popups from "./services/popups";
import http from "./services/http";
import $e from "./services/$e";
import PrimeVue from "primevue/config";
import AutoComplete from "primevue/autocomplete";
import Login from "./services/Login";

import "sweetalert2/dist/sweetalert2.min.css";
import "@vueform/slider/themes/default.css";
import store from "./state/store";

import "../src/design/app.scss";

var app = createApp(App)
  .use(store)
  .use(router)
  .use(require("vue-chartist"))
  .use(BootstrapVue3)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(i18n)
  .use(registerScrollSpy)
  .use(Maska)
  .use(popups)
  .use(http)
  .use($e)
  .use(Login)
  .use(PrimeVue)
  .use(jQuery)
  .use(VueSocialSharing)

app.component("AutoComplete", AutoComplete);

app.mount("#app");

if (localStorage["user"]) {
  const user = JSON.parse(localStorage.user);
  console.log("main js  login : ");
  if (user.roles[0].role.code == "marketing_team_member")
    window.$login
      .loginMarketing(user.email, window.$e.decrypt(user.pass))
      .then((res) => {
        if (res.status == false) {
          localStorage.removeItem("user");
          router.replace("/login");
        }
      });
  else
    window.$login
      .loginUser(user.email, window.$e.decrypt(user.pass))
      .then((res) => {
        if (res.status == false) {
          localStorage.removeItem("user");
          router.replace("/login");
        }
      });
}
